import { graphql } from 'gatsby';
import { useStaticQuery } from 'gatsby';
import React from 'react';
import HeroSection from '../components/Blocks/HeroSection/heroSection';
import LinkText from '../components/Blocks/LinkText/linkText';
import Paragraph from '../components/Blocks/Paragraph/paragraph';
import PlaceImage from '../components/Blocks/PlaceImage/placeImage';
import Section from '../components/Blocks/Section/section';
import FullscreenPhotoSection from '../components/FullscreenPhotoSection/fullscreenPhotoSection';
import Layout from '../components/layout';
import PerformanceSection from '../components/PerformanceSection/performanceSection';
import PhotoLeftTextRight from '../components/PhotoLeftTextRight/photoLeftTextRight';
import SEO from '../components/SEO/seo';
import VideoFullscreen from '../components/VideoFullscreen/videoFullscreen';

const SnobFigma = () => {
  const { site } = useStaticQuery(
        graphql`
          query {
            site {
              siteMetadata {
                siteUrl
              }
            }
          }
        `,
    );
  return (
    <Layout >
        <SEO lang="en" title='Connecting design system in Figma with React library' keywords={['design', 'UX', 'UI', 'Artyom Alekseev', 'Артём Алексеев', 'website', 'usability', 'snob']} description="How to connect code-based components with design system in Figma">
        </SEO>

        <HeroSection animationType="noanima" heading="Connecting Design System in Figma with React Library" desc="How to connect code-based components with design system in Figma."/>

        <Section heading="Components">
            <Paragraph>To keep the design system consistent you need to make sure that it looks exactly as in code. I’m working in Figma. For the Union project, we developed a design system in Storybook and connected it to Union’s Figma UI-library.</Paragraph>
        </Section>

        <Section heading="Link Figma components to Storybook">
            <Paragraph>We added badges with links to the Storybook. To make it easy to find the right code documentation for any component.</Paragraph>
        </Section>

        <FullscreenPhotoSection fileURL="figmaWithLinksToStorybook.png" alt="Component library for Polytech's website"/>

        <Section heading="Link Storybook to Figma">
            <Paragraph>To connect Storybook to Figma we used a plugin called storybook-addon-designs, with it we added a link to Figma frame in the Storybook. You need to make sure that you copied a link to an exact frame of a component.</Paragraph>
            <Paragraph>Now you can see a frame from Storybook.</Paragraph>
        </Section>

        <FullscreenPhotoSection fileURL="storybookWithFigma.png" alt="Component library for Polytech's website"/>
    </Layout>
  ); };

export default SnobFigma;
